.backgroud-2{
    background-color: #1F1D1E;
}

.backgroud-3{
    background-color: #2C2B2B;
}

.home-page{
    background-color: #2C2B2B;
    padding-top: 100px;
}

.home-header{
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
}

.home-header img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.section-boxes{
    padding: 60px 0;
    width: 100%;
}

.products-listing{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.products-listing .product-box{
    width: 400px;
    margin: auto;
    margin-bottom: 70px;
    position: relative;
}

.products-listing .out-of-stock:after{
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #2C2B2B;
    opacity: 0.8;
}

.out-of-stock-message{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    font-size: 27px;
    color: #FFBE00;
    font-family: Montserrat-SemiBold;
}

.product-box .product-image{
    width: 100%;
    height: 480px;
    margin-bottom: 10px;
}

.product-box .product-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-box .product-title{
    font-family: Montserrat-SemiBold;
    font-size: 18px;
    color: #FFBE00;
    margin-bottom: 7px;
}

.product-box .product-description{
    font-size: 15px;
    color: #e5e5e5;
    margin-bottom: 7px;
    line-height: 21px;
}

.product-box .product-price{
    display: block;
    font-size: 16px;
    color: #FFBE00;
    font-family: 'Montserrat-SemiBold';
    margin-bottom: 7px;
}

.section-alt-boxes{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 60px 0;
}

.section-alt-boxes .text-container, .section-alt-boxes .picture-container{
    width: 45%;
}

.section-alt-boxes .text-container{
    padding: 40px 0;
}

.section-alt-boxes .text-container .text-title{
    font-family: Montserrat-SemiBold;
    font-size: 25px;
    color: #e5e5e5;
    margin-bottom: 30px;
    margin-top: 0px;
    text-align: left;
}

.section-alt-boxes .text-container .text-description{
    font-size: 15px;
    color: #e5e5e5;
    text-align: left;
    max-width: 800px;
    margin: auto;
    line-height: 22px;
}

.section-alt-boxes .picture-container {
    height: 300px;
    position: relative;
    z-index: 1;
}

.section-alt-boxes .picture-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width: 1024px)
{
    .home-header{
        height: 430px;
    }

    .section-boxes, .section-text{
        padding: 60px 0;
    }

    .section-alt-boxes{
        flex-flow: column;
        padding: 0 0 60px 0;
    }

    .section-alt-boxes .text-container,
    .section-alt-boxes .picture-container{
        width: 100%;
    }

    .section-alt-boxes .text-container .text-title,
    .section-alt-boxes .text-container .text-description{
        text-align: center;
    }
    
    .about-section div:nth-child(2) .section-alt-boxes{
        flex-flow: column-reverse;
    }

    .product-box .product-description{
        font-size: 17px;
    }

    .product-box .product-title{
        font-size: 19px;;
    }

    .product-box .product-price{
        font-size: 17px;
    }

    .section-alt-boxes .text-container .text-description{
        font-size: 15px;
    }
}

@media(max-width: 500px)
{
    .home-header{
        height: 200px;
    }

    .product-box .product-image{
        height: auto;
    }
}