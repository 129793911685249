body {
  margin: 0;
  font-family: Montserrat-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

p{
  display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"), url("./fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"), url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"), url("./fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat-Bold"), url("./fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat-Black"), url("./fonts/Montserrat-Black.ttf") format("truetype");
  font-weight: bold;
}