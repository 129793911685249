.footer{
    width: 100%;
    background-color: #1F1D1E;
    background-size: cover;
    background-repeat: no-repeat;
}

.footer-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
}

.footer-container .logo{
    cursor: pointer;
    width: 98px;
}

.footer-container .menu-entries-footer{
    display: flex;
    align-items: center;
}

.footer-container .menu-entries-footer .menu-entry{
    color: #FFBE00;
    padding: 10px 20px;
    cursor: pointer;
}

.footer-container .menu-entries-footer .menu-entry:hover{
    color: black;
    background-color: #FFBE00;
}

.footer-container .social-media-container{
    display: flex;
    align-items: center;
}

.footer-container .social-media-container .social-media-entry{
    padding: 20px;
}

.footer-container .social-media-container img{
    height: 36px;
}

@media(max-width: 800px)
{
    .footer-container{
        flex-flow: column;
        padding: 40px 0px;
    }

    .footer-container .logo{
        margin-bottom: 40px;
    }

    .menu-entries-footer{
        flex-flow: column;
        margin-bottom: 40px;
    }

    .menu-entries-footer .menu-entry{
        margin-bottom: 20px;
    }
}