.c-center{
	max-width: 1280px;
	height: 100%;
	margin: auto;
}

*{
	box-sizing: border-box;
}

h,h1,h2,h4{
	margin: 0px;
}

a{
	text-decoration: none;
	cursor: pointer;
}

/* responsive part */

.mobile-device{
	display: none;
}

.desktop-device{
	display: block;
}

@media(max-width: 1280px)
{
		.c-center{
				padding: 0px 20px!important;
		}
}

@media(max-width: 1024px)
{
		.desktop-device{
				display: none;
		}

		.mobile-device{
			display: block;
		}
}

@media(max-width: 500px)
{

}

/* responsive part */

/* scroll bar */

body::-webkit-scrollbar {
	width: 14px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
	background: #2C2B2B;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
		background-color: #FFBE00;    /* color of the scroll thumb */
		border-radius: 20px;       /* roundness of the scroll thumb */
		border: 4px solid #2C2B2B;  /* creates padding around scroll thumb */
}

/* scroll bar */

.overlay:after{
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.2);
		top: 0;
		left: 0;
}