.header{
		background-color: #1F1D1E;
		position: fixed;
		width: 100%;
		z-index: 9;
}

.before-nav-menu{
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		padding: 0 40px;
}

.phone-container a{
	display: flex;
	align-items: center;
}

.phone-container img{
	height: 22px;
}

.phone-container .tel-label{
		color: #FFBE00;
		margin-left: 10px;
		font-family: 'Montserrat-SemiBold';
}

.phone-container *{
	cursor: pointer;
}

.nav-menu{
		width: 100%;
		height: 50px;
		background-color: #FFBE00;
}

.nav-menu-bar{
		display: flex;
		justify-content: space-between;
}

.logo-container{
		position: relative;
}

.logo-container img{
		position: absolute;
		top: -30px;
		width: 98px;
		cursor: pointer;
		z-index: 15;
}

.logo-container h1{
		white-space: nowrap;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 110px;
		font-family: 'Montserrat-Bold';
		font-size: 20px;
		cursor: pointer;
}

.nav-menu .menu-entries{
		display: flex;
		align-items: center;
}

.menu-entry{
		font-family: Montserrat-SemiBold;
		color: #000000;
		font-size: 16px;
		padding: 0 40px;
		font-weight: 700;
		height: 100%;
		display: flex;
		align-items: center;
}

.menu-entries a{
	height: 100%;
}

.nav-menu .menu-entries .menu-entry:hover{
		color: #FFBE00;
		background-color: #1F1D1E;
		cursor: pointer;
}

/* burger menu */

.top-nav{
		position: relative;
}

.menu {
		display: flex;
		flex-direction: row;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
	
	.menu > li {
		margin: 0 1rem;
		overflow: hidden;
	}
	
	.menu-button-container {
		display: none;
		height: 100%;
		width: 30px;
		cursor: pointer;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	#menu-toggle {
		display: none;
	}
	
	.menu-button,
	.menu-button::before,
	.menu-button::after {
		display: block;
		background-color: #000000;
		position: absolute;
		height: 4px;
		width: 30px;
		transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
		border-radius: 2px;
	}
	
	.menu-button::before {
		content: '';
		margin-top: -8px;
	}
	
	.menu-button::after {
		content: '';
		margin-top: 8px;
	}
	
	#menu-toggle:checked + .menu-button-container .menu-button::before {
		margin-top: 0px;
		transform: rotate(405deg);
	}
	
	#menu-toggle:checked + .menu-button-container .menu-button {
		background: rgba(255, 255, 255, 0);
	}
	
	#menu-toggle:checked + .menu-button-container .menu-button::after {
		margin-top: 0px;
		transform: rotate(-405deg);
	}
	
	@media (max-width: 1024px) {
		.menu-button-container {
			display: flex;
		}
		.menu {
				position: absolute;
				top: 56px;
				left: 0;
				flex-direction: column;
				width: 100%;
				justify-content: center;
				align-items: center;
		}
		#menu-toggle ~ .menu li {
			height: 0;
			margin: 0;
			padding: 0;
			border: 0;
			transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
		}
		#menu-toggle:checked ~ .menu li {
			cursor: pointer;
			height: 50px;
			display: flex;
			align-items: center;
			transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
		}
		.menu > li {
			display: flex;
			justify-content: center;
			margin: 0;
			padding: 0.5em 0;
			width: 100%;
			color: white;
			background-color: #222;
		}
	}

/* burger menu */

@media(max-width: 500px)
{
	.logo-container img{
		top: -14px;
		width: 70px;
	}

	.logo-container h1{
		font-size: 18px;
		left: 80px;
	}
}